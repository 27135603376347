<template>
  <v-container fluid  v-if="entity">
    <v-row class=" p-y-6">
      <v-col lg="3" sm="12">
        <BackButton :handler="gotoPartners"/>
      </v-col>
      <v-col lg="6" md="8" sm="10">
        <div class="d-flex gap-x-5 justify-center align-center">
          <div style="max-width: 165px">
            <view-image :height="165" :image="profile_image ? profile_image : ''" :width="165" class="rounded-lg "
                        defaultImage="user"/>
          </div>
          <div class="d-flex justify-start gap-x-6 ml-2">
            <div>
              <h3 class="text-xl mb-1">
                {{ entity_name }}
              </h3>
              <p class="mb-3">{{ entity_type }}</p>

              <SvgIcon class="gap-x-2 m-b-4 font-medium">
                <template v-slot:icon>
                  <ContactPersonIcon/>
                </template>
                {{ contact_person }}
              </SvgIcon>
              <div class="d-block d-lg-flex gap-x-8 mt-6">

                <SvgIcon class="gap-x-2 m-b-4 font-medium ">
                  <template v-slot:icon>
                    <SMSIcon/>
                  </template>
                  {{ email }}
                </SvgIcon>
                <SvgIcon class="gap-x-2 m-b-4 font-medium">
                  <template v-slot:icon>
                    <CallIcon/>
                  </template>
                  {{ phone }}
                </SvgIcon>
              </div>

            </div>
          </div>
        </div>
      </v-col>
      <v-col class="mt-sm-3  d-sm-flex" md="3" sm="2">
        <v-btn
            v-if="checkWritePermission($modules.b2b.partner.slug) && status_id == 2"
            class="mx-2 text_capitalize "
            height="32"
            outlined
            @click="toggleStatus"
        >
          <span class="ml-1">{{ "Activate" }}</span>
        </v-btn>
        <v-menu
            content-class="q-menu"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="text_capitalize "
                elevation="0"
                style="background-color: transparent; min-width: 36px !important; "
                v-bind="attrs"
                v-on="on"
            >
              <DotsIcon height="32" width="32"/>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="checkWritePermission($modules.b2b.partner.slug)" @click="editPartner">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
                <template #icon>
                  <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item v-if="checkWritePermission($modules.b2b.partner.slug) && allowProducts === 1 && status_id === 1"
                         @click="configurePartnerProducts">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Products">
                <template #icon>
                  <ProductsIcon height="16" opacity="1" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item v-if="checkWritePermission($modules.b2b.partner.slug) && status_id === 1" @click="openConfig">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Configuration">
                <template #icon>
                  <ConfigurationIcon opacity="1"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-divider class="mb-2"/>
            <v-list-item v-if="checkWritePermission($modules.b2b.partner.slug)" @click="toggleStatus">
              <SvgIcon :text="(status_id === 1 ? 'Deactivate' : 'Activate')+' Partner'"
                       class="font-medium text-sm gap-x-2">
                <template #icon>
                  <ActivateIcon/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item v-if="checkDeletePermission($modules.b2b.partner.slug)" @click="deletePartnerWarning">
              <SvgIcon class="red--text font-medium text-sm gap-x-2" text="Delete Partner">
                <template #icon>
                  <DeleteIcon opacity="1" stroke="red"/>
                </template>
              </SvgIcon>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <div class="row mt-5">
      <div class="col-12">
        <v-card class="shadow-0">
          <v-card-actions class="text-center " style="border-top: 1px solid #F1F1F1">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                class="q-tabs-secondary shadow-0  border-bottom"
                light
                slider-color="transparent"
            >
              <v-tab
                  active-class="active_tab_stroke_icon"
                  class=" border-bottom "
                  href="#about"
              >
                <SvgIcon text="About">
                  <template v-slot:icon>
                    <AboutIcon class="svg-stroke-neon"/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab
                  v-if="status_id == 1"
                  active-class="active_tab_fill_icon"
                  class="d-flex border-bottom "
                  href="#bookings"
              >
                <SvgIcon text="Bookings">
                  <template v-slot:icon>
                    <BookingsIcon class="svg-stroke-neon"/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab
                  v-if="status_id == 1"
                  active-class="active_tab_stroke_icon"
                  class="d-flex border-bottom "
                  href="#financials"
              >
                <SvgIcon text="Financials">
                  <template v-slot:icon>
                    <FinancialsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab
                  v-if="status_id == 1"
                  active-class="active_tab_stroke_icon"
                  class="d-flex border-bottom "
                  href="#analytics"
              >
                <SvgIcon text="Analytics">
                  <template v-slot:icon>
                    <AnalyticsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
            </v-tabs>
          </v-card-actions>
          <v-tabs-items v-model="tab">
            <v-tab-item class="p-4" value="about">
              <B2bAbout v-if="b2bId && entity" :entity="entity"/>
            </v-tab-item>
            <v-tab-item class="p-4" value="bookings">
              <b2b-bookings
                  v-if="b2bId"
                  :b2bId="b2bId"
                  :entity_type="entity_type"
              ></b2b-bookings>
            </v-tab-item>

            <v-tab-item class="p-4" value="financials">
              <b2b-sales :b2bId="b2bId" :entity_type="entity_type"></b2b-sales>
            </v-tab-item>

            <v-tab-item v-if="status_id == 1" class="p-4" value="analytics">
              <v-container fluid>
                <v-row>
                  <v-col class="ml-3" md="4">
                    <date-range-field
                        :dateType="dateToggle"
                        :dense="true"
                        :outlined="true"
                        class-name="q-text-field shadow-0"
                        @periodChange="dateChange"
                    />
                  </v-col>
                  <v-col class=" d-flex justify-center" md="4">
                    <v-btn-toggle
                        v-model="dateToggle"
                        borderless
                        class="q-tabs shadow-0 bordered"
                        style="height: 40px !important;"
                        tile
                    >
                      <v-btn height="40" value="DATE">Day</v-btn>
                      <v-btn height="40" value="MONTH">Month</v-btn>
                      <v-btn height="40" value="YEAR">Year</v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-container>
              <v-row>
                <b2b-bookings-graph
                    :b2bId="b2bId"
                    :date1="date1"
                    :date2="date2"
                    :exportPermission="checkExportPermission($modules.b2b.partner.slug)"
                    :showProductType="true"
                    :stackType="stackType"
                    @stackChange="stackChangeCapture"
                    :filter-by="dateToggle"
                    @update:filterBy="(newValue) => {dateToggle = newValue}"
                ></b2b-bookings-graph>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="thresholdModel" max-width="500px" persistent scrollable width="40%">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title class="border-bottom">
            <div class="w-full">
              <div class="d-flex justify-space-between align-center">
                <p class="mb-0 font-medium">
                  Configuration
                </p>
                <v-btn class="shadow-0" fab x-small @click="closeConfigModel">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="align-end">
                <v-col cols="12" md="9" sm="6">
                  <label for="">
                    Visitor threshold
                  </label>
                  <v-text-field
                      v-model="threshold"
                      :rules="[
                      (v) => !!v || 'Visitor threshold is required',
                      (v) => !isNaN(v) || 'Visitor threshold must be Number',
                    ]"
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-btn
                      v-if="checkWritePermission($modules.b2b.partner.slug)"
                      class="white--text teal-color"
                      height="40"
                      @click="saveConfig"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <AddEditPartner
        v-if="addEntityDialogue"
        :add-entity-dialogue="addEntityDialogue"
        :partner="entity"
        @close="()=>{addEntityDialogue=false}"
        @refresh="getB2bDetails"
    />
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
  </v-container>
</template>
<script>
// import B2bCustomerAnalysis from "@/components/B2b/B2bCustomerPieCharts.vue";
import B2bBookingsGraph from "@/components/Chart/B2bViewPartnerGraph";
import B2bBookings from "@/components/B2b/B2bBookings.vue";
import B2bSales from "@/components/B2b/B2bSales.vue";
import moment from "moment";
import SMSIcon from "@/assets/images/trainers/email-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import CallIcon from "@/assets/images/trainers/call-icon.svg";
import BackButton from "@/components/Common/BackButton.vue";
import AddEditPartner from "@/views/B2b/AddEditPartner.vue";
import AboutIcon from "@/assets/images/partners/about.svg";
import BookingsIcon from "@/assets/images/partners/calendar.svg";
import FinancialsIcon from "@/assets/images/partners/financials.svg";
import ContactPersonIcon from "@/assets/images/partners/contact-person.svg";
import AnalyticsIcon from "@/assets/images/partners/analytics.svg";
import DotsIcon from "@/assets/images/partners/dots.svg";
import DateRangeField from "@/components/Fields/DateRangeField.vue";
import B2bAbout from "@/components/B2b/B2bAbout.vue";
import EditIcon from "@/assets/images/tables/edit.svg";
import ProductsIcon from "@/assets/images/partners/products.svg";
import ConfigurationIcon from "@/assets/images/partners/configuration.svg";
import DeleteIcon from "@/assets/images/misc/delete-icon.svg";
import ActivateIcon from "@/assets/images/partners/activate.svg";

export default {
  props: {
    "date-toggle": {type: String, default: "DATE"},
    //     b2bId: { type: Number, default: null },
  },
  components: {
    DeleteIcon,
    B2bAbout,
    DateRangeField,
    AboutIcon,
    BookingsIcon,
    FinancialsIcon,
    AnalyticsIcon,
    ContactPersonIcon,
    DotsIcon,
    AddEditPartner,
    BackButton, CallIcon, SvgIcon, SMSIcon,
    // "b2b-customer-analysis": B2bCustomerAnalysis,
    "b2b-bookings-graph": B2bBookingsGraph,
    "b2b-bookings": B2bBookings,
    "b2b-sales": B2bSales,
    EditIcon,
    ProductsIcon,
    ConfigurationIcon,
    ActivateIcon
  },
  mounted() {
    if (typeof this.$route.params.data != "undefined") {
      let id = parseInt(atob(this.$route.params.data));
      this.params.b2b_id = id;
      this.b2bId = id;
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }

    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.checkProductEnable()
      });
    } else {
      this.checkProductEnable();
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
  },
  data() {
    return {
      addEntityDialogue: false,
      valid: false,
      thresholdModel: false,
      threshold: null,
      date1: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      productTypeIds: [],
      venueServiceIds: [],
      stackType: "all",
      reload: false,
      b2bId: null,
      entity_name: "null",
      description: "null",
      contact_person: "null",
      phone: "null",
      profile_image: "null",
      email: "null",
      entity_type: "null",
      credit_due: 0,
      credit_limit: 0,
      credit_cap: 0,
      status_id: 1,
      documents: [],
      confirmModel: {
        id: null,
        title: "null",
        description: "null",
      },
      params: {
        venue_service_ids: [],
        product_ids: [],
        product_type_ids: [8],
        b2b_id: null,
      },
      bookings: [],
      entity: null,
      tab: 'about',
      allowProducts: 0,
      dateToggle: "DATE",
    };
  },
  watch: {
    b2bId(val) {
      if (val != null) {
        this.getB2bDetails();
      }
    },
    params: {
      immediate: true,
      handler(params) {
        console.log("params");
        console.log(params);
        this.reload = true;
      },
    },
  },
  methods: {
    checkProductEnable() {
      if (this.b2bConfig && this.b2bConfig.enable_specific_products) {
        this.allowProducts = 1;
      } else {
        this.allowProducts = 0;
      }
    },
    closeConfigModel() {
      this.threshold = null;
      this.thresholdModel = false;
    },
    saveConfig() {
      this.showLoader();
      let data = {customer_threshold: this.threshold, b2b_id: this.b2bId};
      this.$http
          .post(`venues/b2b/configuration`, data)
          .then((res) => {
            if (res.status == 200 && res.data.status == true) {
              this.hideLoader();
              this.showSuccess("Configuration updated successfully");
              this.closeConfigModel();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    openConfig() {
      this.showLoader();
      this.$http
          .get(`venues/b2b/configuration?b2b_id=${this.b2bId}`)
          .then((res) => {
            if (res.status == 200 && res.data.status == true) {
              this.hideLoader();
              let data = res.data.data;
              this.threshold = data.customer_threshold;
              this.thresholdModel = true;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    dateChange(data) {
      this.date1 = data.date1;
      this.date2 = data.date2;
    },
    stackChangeCapture(data) {
      this.stackType = data;
    },
    gotoPartners() {
      this.$router.push({
        name: "B2bPartners",
      });
    },
    getB2bDetails() {
      this.showLoader("Loading...");
      this.$http
          .get(`venues/b2b/${this.b2bId}`)
          .then((res) => {
            if (res.status == 200 && res.data.status == true) {
              let data = res.data.data;
              this.entity = data;
              this.entity.enable_commission = this.entity.enable_commission.toString()
              this.entity.allow_credit = this.entity.allow_credit.toString()
              this.entity_name = data.entity_name;
              this.description = data.description;
              this.profile_image = data.image_path;
              this.contact_person = data.contact_person_name;
              this.phone = data.phone;
              this.credit_due = data.credit_due;
              this.credit_limit = data.credit_limit;
              this.credit_cap = data.credit_cap;
              this.email = data.email;
              this.entity_type = data.type.name;
              this.status_id = data.status_id;
              this.documents = data.documents;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.$router.push({
              name: "B2bPartners",
            })
          })
          .finally(()=>{
            this.hideLoader();
          });
    },
    confirmActions(data) {
      if (data.type === "b2b_toggle") {
        this.confirmModel.id = null;
        this.toggleB2b();
      } else if (data.type === 'delete') {
        this.deleteEntity(this.confirmModel.id);
        this.confirmModel.id = null;
      }
      this.$forceUpdate();
    },
    toggleStatus() {
      console.log("clicked");
      this.confirmModel = {
        id: this.b2bId,
        title: "Do you want to change this status?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "b2b_toggle",
      };
    },
    toggleB2b() {
      this.$http
          .get(`venues/b2b/toggle/${this.b2bId}`)
          .then((res) => {
            if (res.status == 200 && res.data.status == true) {
              this.getB2bDetails();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    configurePartnerProducts() {
      this.$router.push({
        name: "B2bPartnerProducts",
        params: {data: btoa(this.b2bId)},
      });
    },
    editPartner() {
      this.entity.deletedDocuments = [];
      this.addEntityDialogue = true;
    },
    deletePartnerWarning() {
      this.confirmModel = {
        id: this.b2bId,
        title: `Do you want to delete this Entity?`,
        description: `By clicking <b>Yes</b> you can confirm delete this Entity.  Do you need to continue your action ?`,
        type: "delete",
      };
    },
    deleteEntity(id) {
      this.$http
          .get(`venues/b2b/deactivate/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.gotoPartners();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

  },
};
</script>

<style scoped>
.tab_active {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.flat-table {
  display: block;
  overflow: auto;
  width: auto;
  border-radius: 10px;
}

.flat-table th {
  background-color: rgb(4, 148, 184);
  color: white;
  font-weight: normal;
  padding: 8px 12px;
  text-align: center;
}

.flat-table td {
  background-color: #eeeeee;
  color: #6f6f6f;
  padding: 8px 12px;
}
</style>
