<script>
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "B2bAbout",
  components: {SvgIcon, ExportIcon},
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  methods: {
    getDocumentPath(document) {
      window.open(this.s3BucketURL + document.file_path, "_blank");
    },
  },
}
</script>

<template>
  <div>
    <div v-if="entity.documents && entity.documents.length" class="mb-8">
      <h4 class="text-lg font-semibold text-blue mb-2">
        Documents
      </h4>
      <div class="d-flex align-center gap-x-4">
        <div v-for="document in entity.documents"
             :key="document.felid_name" class="bordered rounded-2 text-neon bg-neon opacity-70 py-2 px-6 pointer"
             @click="getDocumentPath(document)">
          <SvgIcon :text="document.felid_name">
            <template #icon>
              <ExportIcon/>
            </template>
          </SvgIcon>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h4 class="text-lg font-semibold text-blue mb-2">
        Financial Details
      </h4>
      <div class="financial-details">
        <v-row>
          <v-col sm="4" lg="2">
            <p class="text-light-gray">
              Credit Limit
            </p>
            <p>
              {{ entity.credit_limit | toCurrency }}
            </p>
          </v-col>
          <v-col sm="4" lg="2">
            <p class="text-light-gray">
              Credit Cap
            </p>
            <p>
              {{ entity.credit_cap | toCurrency }}
            </p>
          </v-col>
          <v-col sm="4" lg="2">
            <p class="text-light-gray">
              Invoice Due
            </p>
            <p>
              {{ entity.credit_due | toCurrency }}
            </p>
          </v-col>
          <v-col v-if="entity.trn_number && entity.trn_number !== 'undefined' && entity.trn_number != 'null'" sm="4" lg="2">
            <p class="text-light-gray">
              TRN Number
            </p>
            <p>
              {{ entity.trn_number }}
            </p>
          </v-col>
          <v-col v-if="entity.trade_license_no && entity.trade_license_no !== 'undefined' && entity.trade_license_no != 'null'" sm="4" lg="2">
            <p class="text-light-gray">
              Trade License No
            </p>
            <p>
              {{ entity.trade_license_no }}
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class=" mt-8">
      <h4 class="text-lg font-semibold text-blue mb-2">
        Partner Details
      </h4>
      <div class="financial-details">
        <v-row>
          <v-col lg="2" sm="4" v-if="entity.institution_type">
            <p class="text-light-gray">
              Institution Type
            </p>
            <p>
              {{ entity.institution_type }}
            </p>
          </v-col>
          <v-col lg="2" sm="4" v-if="entity.sector">
            <p class="text-light-gray">
              Entity Status
            </p>
            <p>
              {{ entity.sector }}
            </p>
          </v-col>
        </v-row>
        <div class="mt-6" v-if="entity.description">
          <p class="text-light-gray">
            Description
          </p>
          <p>
            {{ entity.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.financial-details {
  border-radius: 1rem;
  background: #F8FAFB;
  padding: 1.5rem;

  p {
    font-size: 1rem;
    margin: 0;
  }
}
</style>