<template>
  <v-container fluid>
        <BarChart
          :exportPermission="exportPermission"
          v-bind="bookings"
        ></BarChart>
    <br>
        <BarChart
          :exportPermission="exportPermission"
          v-bind="sales"
        ></BarChart>
  </v-container>
</template>

<script>
import BarChart from "./BarChart";
export default {
  components: {
    BarChart,
  },
  props: {
    date1: { type: String, default: null },
    date2: { type: String, default: null },
    b2bId: { type: Number, default: null },
    params: {
      type: Object,
      default: () => {
        return { venue_service_ids: [], product_ids: [], product_type_ids: [] };
      },
    },
    "export-permission": { type: Boolean, default: true },
    "show-venue-service": { type: Boolean, default: true },
    "show-product-type": { type: Boolean, default: false },
    "stack-type": { type: String, default: "all" }, //service,type,all
    reload: { type: Boolean, default: false },
    filterBy: { type: String, default: "DATE" },
  },
  watch: {
    date1: {
      handler(val) {
        if (val) {
          this.callGraph();
        }
      },
    },
    date2: {
      handler(val) {
        if (val) {
          this.callGraph();
        }
      },
    },
    params: {
      immediate: true,
      handler(params) {
        if (params.venue_service_ids.length > 0) {
          this.venueServiceIds = params.venue_service_ids;
        }
        if (params.product_type_ids.length > 0) {
          this.productTypeIds = params.product_type_ids;
        }
        if (params.product_ids.length > 0) {
          this.productIds = params.product_ids;
        }
      },
    },
    stackType: {
      immediate: true,
      handler() {
        this.callGraph();
      },
    },
    stack(val) {
      this.$emit("stackChange", val);
    },
    reload: {
      immediate: true,
      handler(val) {
        if (val) {
          this.callGraph();
        }
      },
    },
  },
  data() {
    return {
      agePieChart: null,
      genderPieChart: null,
      countryPieChart: null,
      venueServiceIds: [],
      productTypeIds: [],
      productIds: [],
      bookings: {
        heading: "Bookings",
        type: "totalBooking",
        fullscreenFlag: true,
        yTitle: "Bookings",
        xTitle: "Partners",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      sales: {
        heading: "Sales",
        type: "totalSales",
        fullscreenFlag: true,
        yTitle: "Sales",
        xTitle: "Partners",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      stack: "all",
      mandatory: false,
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    dateToggle: {
      get: function () {
        return this.filterBy
      },
      set: function (newValue) {
        this.$emit('update:filterBy', newValue)
      }
    }
  },
  methods: {
    callGraph() {
      this.getOrderSalesGraphData();
      this.getOrderBookingGraphData();
    },
    // dateChange(data) {
    //   this.date1 = data.date1;
    //   this.date2 = data.date2;
    //   this.callGraph();
    // },
    getOrderSalesGraphData() {
      this.sales.loader = true;
      let url = "?type=sales";
      url += this.getApiFilter();
      this.$http
        .get(`venues/graphs/orders${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            let chartData = this.getGraphData(data, "Sales");
            this.sales.heighest = Math.ceil(chartData.largest * 1.1);
            this.sales.chartData = chartData.data;
            this.sales.exportData = data;
            this.sales.loader = false;
            this.mandatory = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getOrderBookingGraphData() {
      this.bookings.loader = true;
      let url = "?type=bookings";
      url += this.getApiFilter();
      this.$http
        .get(`venues/graphs/orders${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            let chartData = this.getGraphData(data, "Bookings");
            this.bookings.heighest = Math.ceil(chartData.largest * 1.1);
            this.bookings.chartData = chartData.data;
            this.bookings.exportData = data;
            this.bookings.loader = false;
            this.mandatory = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getApiFilter() {
      let url = `&date_type=${this.dateToggle.toLowerCase()}&start_date=${
        this.date1
      }&end_date=${this.date2}&stack_type=${this.stackType}&b2b_id=${
        this.b2bId
      }`;

      if (
        this.venueServiceIds.length != this.venueServices.length &&
        this.venueServiceIds.length > 0
      ) {
        url += `&${this.venueServiceIds
          .map((n, index) => `venue_service_ids[${index}]=${n}`)
          .join("&")}`;
      }

      if (
        this.productTypeIds.length != this.productTypes.length &&
        this.productTypeIds.length > 0
      ) {
        url += `&${this.productTypeIds
          .map((n, index) => `product_type_ids[${index}]=${n}`)
          .join("&")}`;
      }

      if (this.productIds.length > 0) {
        url += `&${this.productIds
          .map((n, index) => `product_ids[${index}]=${n}`)
          .join("&")}`;
      }

      return url;
    },
    getGraphData(data, type) {
      var graphData = [];
      let largest = 0;
      if (data.length > 0) {
        let keys = Object.keys(data[0]).filter(
          (ele) => ele.toLowerCase() != "date"
        );
        let keysLength = keys.length;
        data.forEach((element, index) => {
          let di = 0;
          let lar = 0;
          for (let i = 0; i <= keysLength; i++) {
            if (index == 0) {
              graphData[i] = {};
              graphData[i].data = {};
              graphData[i].name = keys[i];
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.Date] = element[keys[i]];
              graphData[keysLength] = {};
              graphData[keysLength].name = "Total";
              graphData[keysLength].data = {};
              graphData[keysLength].data[element.Date] = 0;
              di = di + 1;
            } else {
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.Date] = element[keys[i]];
              di = di + 1;
            }
          }
          graphData[keysLength].data[element.Date] = 0;
          if (largest < lar) {
            largest = lar;
          }
        });
        if (keysLength == 1 && keys[0] == type) {
          graphData[0].library = {};
          graphData[0].library.datalabels = {
            color: "rgba(0,0,49)",
            align: "end",
            anchor: "end",
            font: {
              size: 12,
              weight: 700,
            },
          };
        }
        if (this.stackType != "all") {
          graphData[keysLength].library = {};
          graphData[keysLength].library.datalabels = {
            color: function() {
              return "rgba(0,0,49)";
            },
            formatter: (value, ctx) => {
              let total = ctx.chart.$totalizer.totals[ctx.dataIndex];
              if (type == "Bookings") {
                let data = new Intl.NumberFormat("en-US", {
                  maximumSignificantDigits: 4,
                }).format(total);
                return data + (total > 1 ? " bookings" : " booking");
              } else if (type == "Sales") {
                var formatter = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  minimumFractionDigits: 0,
                });
                return formatter.format(total);
              } else {
                return new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  // maximumSignificantDigits: 3,
                }).format(total);
              }
            },
            font: {
              size: 12,
              weight: 700,
            },
            align: "end",
            anchor: "end",
            display: function(ctx) {
              return (
                ctx.datasetIndex === ctx.chart.$totalizer.utmost &&
                ctx.chart.$totalizer.totals[ctx.dataIndex] !== 0
              );
            },
          };
        }
      }
      return { data: graphData, largest: largest };
    },
  },
};
</script>

<style></style>
