<template>
  <div class="md-card md-theme-default mt-8 shadow rounded-5" >
    <div class="md-card-content md-card-table">
      <div class="header_title">Bookings</div>
      <div>
        <div class="d-flex justify-end align-center gap-x-4 gap-y-4 mb-4 flex-wrap">
          <v-autocomplete
              v-model="columns"
              :items="columnData"
              class="q-autocomplete shadow-0"
              dense
              hide-details="auto"
              item-text="name"
              item-value="value"
              label="Table Filter"
              multiple
              outlined
              style="max-width: 180px;"
          >
            <template
                v-if="columns.length == columnData.length"
                v-slot:selection="{ index }"
            >
              <span v-if="index == 0">All Fields</span>
            </template>
            <template v-else v-slot:selection="{ item, index }">
              <span v-if="index == 0">{{ item.name }}</span>
              <span v-if="index == 1">, ...</span>
            </template>
          </v-autocomplete>
          <div class="d-flex align-center gap-x-4">
            <v-autocomplete
                v-model="searchParams.time_intervel"
                :items="timeDuration"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                item-text="title"
                item-value="name"
                outlined
                placeholder="Time Period"
                @change="changeFxn"
            ></v-autocomplete>
            <template v-if="flag">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  dense
                  max-width="200px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="date1Formatted"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.start_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  max-width="200px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="date2Formatted"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.end_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn class="mr-2" color="#00b0af" dark height="42" @click="search">
                Go
              </v-btn>
            </template>
          </div>
          <div
              v-if="checkExportPermission($modules.b2b.partner.slug)"
          >
            <v-btn class="export-button text-capitalize" elevation="0" height="42" small @click="exportData">
              <SvgIcon text="Export">
                <template v-slot:icon>
                  <ExportIcon/>
                </template>
              </SvgIcon>
            </v-btn>
          </div>

          <div
              v-if="checkWritePermission($modules.b2b.partner.slug)"
          >
            <v-btn
                class="bg-blue white--text text-capitalize"
                height="42"
                @click="addDialogue = true"
            >
              <AddIcon class="mr-2"/>
              Add Bookings
            </v-btn>
          </div>

        </div>

        <div class="table-responsive">
          <table class="table border-collapse ">
            <thead>
            <tr class="opacity-70 tr-neon tr-rounded ">

              <th v-if="checkTableColumnVisibility('timestamp')">
                <div class="">Timestamp</div>
              </th>
              <th
                  v-if="checkTableColumnVisibility('due_date')"
              >
                <div>
                  Due Date
                </div>
              </th>
              <th
                  v-if="checkTableColumnVisibility('guests')"
                  style="white-space: nowrap"
              >
                <div>
                  # of Guests
                </div>
              </th>
              <th
                  v-if="checkTableColumnVisibility('guest_details')"
                  class="md-table-head"
                  style="position: relative"
              >
                <div>
                  Guest Details
                </div>
              </th>
              <th
                  v-if="checkTableColumnVisibility('tickets')"
                  class="md-table-head"
              >
                <div>
                  Tickets
                </div>
              </th>

              <th
                  v-if="checkTableColumnVisibility('order_status')"
                  class="md-table-head"
              >
                <div>
                  Transaction Status
                </div>
              </th>

              <th
                  v-if="checkTableColumnVisibility('order_payment_status')"
                  class="md-table-head"
              >
                <div>
                  Status
                </div>
              </th>

              <th
                  v-if="checkTableColumnVisibility('details')"
                  class="md-table-head"
              >
                <div>
                  Details
                </div>
              </th>
              <th
                  v-if="checkTableColumnVisibility('bill')"
                  class="md-table-head"
              >
                <div>
                  Bill
                </div>
              </th>
              <th
                  class="md-table-head"
              >
                <div>
                  Tickets
                </div>
              </th>
            </tr>
            </thead>

            <tbody v-if="searchDatas && searchDatas.length > 0">
            <tr
                v-for="(data, index) in searchDatas"
                :key="index"
                class="md-table-row"
            >
              <td
                  v-if="checkTableColumnVisibility('timestamp')"
                  class="md-table-cell"
              >
                <div class="md-table-cell-container">
                      <span class="text-truncate">{{
                          data.timestamp | timeStamp
                        }}</span>
                </div>
              </td>
              <td
                  v-if="checkTableColumnVisibility('due_date')"
                  class="md-table-cell"
              >
                <div class="md-table-cell-container">
                  {{ data.facility_booking_date | dateformat }}
                </div>
              </td>
              <td
                  v-if="checkTableColumnVisibility('guests')"
                  class="md-table-cell"
              >
                <div class="md-table-cell-container">
                  {{ data.guests_count }}
                </div>
              </td>
              <td
                  v-if="checkTableColumnVisibility('guest_details')"
                  class="md-table-cell"
              >
                <div class="md-table-cell-container">
                  <v-btn
                      color="#00b0af"
                      dark
                      height="28"
                      @click="exportIndividual(data.id)"
                  >List
                    <v-icon dark right>
                      mdi-download
                    </v-icon>
                  </v-btn
                  >
                </div>
              </td>
              <td
                  v-if="checkTableColumnVisibility('tickets')"
                  class="md-table-cell"
              >
                <div class="md-table-cell-container">
                  {{ data.ticket_type }}
                </div>
              </td>
              <td
                  v-if="checkTableColumnVisibility('order_status')"
                  class="md-table-cell"
              >
                <div class="md-table-cell-container">
                  {{ data.order_status }}
                </div>
              </td>
              <td
                  v-if="checkTableColumnVisibility('order_payment_status')"
                  class="md-table-cell"
              >
                <div class="md-table-cell-container">
                  {{ data.order_payment_status }}
                </div>
              </td>
              <td
                  v-if="checkTableColumnVisibility('details')"
              >
                <v-btn normal small @click="viewBookingDetails(data)">
                  View
                </v-btn>

              </td>
              <td>
                <div
                    v-if="data.fb_id && data.is_booking_approved || data.event_booking_id"
                    class="md-table-cell-container"
                >
                  <v-btn normal small @click="getOrderDetails(data)">
                        <span v-if="data.order_status == 'Unpaid'"
                        >Invoice</span
                        >
                    <span v-else-if="data.status == 'Refund'"
                    >Refund Bill</span
                    >
                    <span v-else>Receipt</span>
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                      class="ma-2 white--text green-color"
                      text
                      @click="approveBooking(data.fb_id)"
                  >
                    Approve
                  </v-btn>
                </div>
              </td>
              <td>
                <v-btn v-if="data.order_status === 'Paid'" normal small @click="downloadTickets(data)">
                  Tickets
                </v-btn>
                <span v-else>
                      N/A
                    </span>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td class="text-center pa-3" colspan="10">
                <span> No records</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="4">
            <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
              <div style="width: 80px">
                <v-select
                    v-model="perPage"
                    :items="[10, 15, 25, 50]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="q-autocomplete text-sm"
                    flat
                    hide-details
                    solo
                    @change="search()"
                ></v-select>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <b2b-add-bookings
        :addBookingDialogue="addDialogue"
        :b2bId="b2bId"
        :entityType="entity_type"
        @close="(addDialogue = false), search()"
    ></b2b-add-bookings>
    <order-details
        :id="orderId"
        :ids="orderIds"
        :log="true"
        @close="(orderId = null), (orderIds = null), search()"
    ></order-details>
    <v-dialog v-model="bookingDetailsModal" max-width="500" scrollable>
      <v-card>
        <v-toolbar color="teal" dark height="80">
          <v-toolbar-title>Booking Details</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div style="margin-top:10px;">
            <v-row>
              <v-col cols="12" md="6" sm="12" v-if="bookingDetails.date"><span class="label">Date:</span>
                <br>
                <span class="details">{{ bookingDetails.date | dateformat }}</span>
              </v-col>
              <v-col cols="12" md="6" sm="12" v-if="bookingDetails.start_time"><span class="label">Time: </span>
                <br>
                <span class="details">{{ bookingDetails.start_time | timeFormat }}</span>
              </v-col>
              <v-col cols="12" md="6" sm="12"><span class="label">Guest Count: </span>
                <br>
                <span class="details">{{ bookingDetails.guests_count }}</span>
              </v-col>
              <v-col v-if="bookingDetails.has_pod" cols="12" md="6" sm="12"><span class="label">POD type: </span>
                <br>
                <span class="details">{{ bookingDetails.pod_type }}</span>
              </v-col>
              <v-col v-if="bookingDetails.order_notes" cols="12" md="12" sm="12"><span
                  class="label">Instructions: </span>
                <br>
                <span class="details">{{ bookingDetails.order_notes }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ma-2 white--text blue-color" text @click="closeDetails()"
          >Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import B2bAddBookings from "@/components/B2b/B2bAddBooking.vue";

import OrderDetails from "../../components/Order/OrderDetails.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";

export default {
  components: {
    AddIcon,
    ExportIcon, SvgIcon,
    "b2b-add-bookings": B2bAddBookings,
    OrderDetails,
  },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
    b2bId: {
      type: Number,
      default: null,
    },
    entity_type: {
      type: String,
      default: "b2b",
    },
  },
  watch: {
    page() {
      this.search();
    },
  },
  data() {
    return {
      orderId: null,
      orderIds: null,
      addDialogue: false,
      searchDatas: [
        // {
        //   id: "1",
        //   timestamp: "2022-11-20 12:00:00",
        //   due_date: "2022-11-25 14:00:00",
        //   guests_count: "35",
        //   ticket_type: "Silver",
        //   order_status: "Paid",
        // },
        // {
        //   id: "2",
        //   timestamp: "2022-11-22 11:12:00",
        //   due_date: "2022-11-26 15:35:00",
        //   guests_count: "20",
        //   ticket_type: "Gold",
        //   order_status: "Pending",
        // },
        // {
        //   id: "3",
        //   timestamp: "2022-11-23 01:00:00",
        //   due_date: "2022-11-25 12:15:00",
        //   guests_count: "25",
        //   ticket_type: "Family",
        //   order_status: "Paid",
        // },
        // {
        //   id: "4",
        //   timestamp: "2022-11-19 16:57:00",
        //   due_date: "2022-11-27 08:30:00",
        //   guests_count: "40",
        //   ticket_type: "Platinum",
        //   order_status: "Paid",
        // },
      ],
      menu1: false,
      menu2: false,
      flag: false,
      currentPage: 0,
      pageSize: 10,
      page: 1,
      perPage: 10,
      totalPages: 1,
      bookingDetailsModal: false,
      bookingDetails: {
        date: null,
        start_time: null,
        guests_count: null,
        has_pod: false,
        pod_type: null,
      },
      timeDuration: [
        {name: "All", title: "All"},
        {name: "day", title: "This Day"},
        {name: "week", title: "This Week"},
        {name: "month", title: "This Month"},
        {name: "year", title: "This Year"},
        {name: "custom", title: "Custom Duration"},
      ],
      columnData: [
        {name: "Due date", value: "due_date"},
        {name: "Timestamp", value: "timestamp"},
        {name: "Guests", value: "guests"},
        {name: "Guest details", value: "guest_details"},
        {name: "Tickets", value: "tickets"},
        {name: "Transaction Status", value: "order_status"},
        {name: "Order Status", value: "order_payment_status"},
        {name: "Bill", value: "bill"},
        {name: "Details", value: "details"},
      ],
      columns: [
        "order_status",
        "order_payment_status",
        "guest_details",
        "tickets",
        "guests",
        "timestamp",
        "due_date",
        "bill",
        "details",
      ],
      orderBy: "id",
      orderDir: "DESC",
      searchParams: {
        start_date: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    getOrderDetails(data) {
      this.orderId = data.id;
    },

    approveBooking(id) {
      this.showLoader("Approving");
      this.$http
          .get(`venues/facilities/bookings/booking-status/${id}/approve`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.search();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    exportData() {
      let url = "";
      url = "&status=all";
      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }

      this.$http
          .get(`venues/b2b/booking/export-all?b2b_id=${this.b2bId}${url}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.downloadFile(response, "Bookings_Export_");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    exportIndividual(id) {
      this.$http
          .get(`venues/b2b/booking/export?order_id=${id}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.downloadFile(response, "Customer_Export_");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.search();
      }
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    search() {
      let url = "";
      url = "&status=all";

      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      console.log("searching")
      this.$http
          .get(
              `venues/b2b/booking?b2b_id=${this.b2bId}&page=` +
              this.page +
              "&per_page=" +
              (this.perPage != null ? this.perPage : 10) +
              url
          )
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status) {
              this.searchDatas = response.data.data;
              this.totalPages = response.data.total_pages;
              if (typeof this.searchParams.offline_user_id != "undefined") {
                this.showUserModel(this.searchParams.offline_user_id);
              }
              this.$forceUpdate()
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    viewBookingDetails(data) {
      let url = `venues/b2b/booking/${data.fb_id}`;
      if(data.event_booking_id){
        url = `venues/b2b/booking/event/${data.event_booking_id}`
      }
      this.showLoader("Loading..");
      this.$http.get(url)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.bookingDetailsModal = true
              this.bookingDetails = response.data.data
              return true;
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    closeDetails() {
      this.bookingDetailsModal = false
      this.bookingDetails = {
        date: null,
        start_time: null,
        guests_count: null,
        has_pod: false,
        pod_type: null,
      }
    },
    downloadTickets(data) {
      console.log(data)
      this.showLoader("Downloading");
      let url = `venues/b2b/booking/download-tickets/${data.id}`;
      if(data.event_booking_id){
        url = `venues/b2b/booking/download-event-tickets/${data.id}`
      }
      this.$http.get(url, {
        responseType: "blob",
      }).then(response => {
        const fileName = "tickets_"
        this.downloadFile(response, fileName, 'zip');
        this.showSuccess(`Tickets downloaded successfully`);

        return true;
      }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
  }
};
</script>
<style scoped>
.label {
  margin: 5px;
  font-size: 14px;

}

.details {
  color: black;
  margin: 5px;
  font-size: 14px;
  font-weight: 600;
}
</style>