<template>
  <v-dialog v-model="addBookingDialogue" scrollable width="60%" persistent>
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="border-bottom">
          <div class=" w-full">
            <div class="d-flex justify-space-between align-center">
              <p class="mb-0 font-medium">
                Add Booking
              </p>
              <v-btn class="shadow-0" fab x-small @click="closeDialogueConfirm">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" :sm="3" :md="3">
                <label for="">Type*</label>
                <v-select
                  v-model="booking.booking_type"
                  :items="productTypes"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  label=""
                  required
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
                  @change="getConfigurations"
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="3" :md="3" v-if="booking.booking_type === 6">
                <label for="">Service*</label>
                <v-select
                  v-model="booking.product_type"
                  :items="venueServices"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  label=""
                  required
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
                  @change="getConfigurations"
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="3" :md="3" v-if="booking.booking_type === 6">
                <label for="">Facility*</label>
                <v-select
                  v-model="booking.facility_id"
                  :items="facilities"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  label=""
                  required
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="3" :md="3" v-if="booking.booking_type === 3">
                <label for="">Event*</label>
                <v-select
                  v-model="booking.event_id"
                  :items="events"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  label=""
                  required
                  @change="getEventTickets"
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
                ></v-select>
              </v-col>
              <!-- <v-col
                cols="3"
                class="pb-0"
                style="padding-top: 25px;"
                v-if="openDated == 1"
              >
                <v-switch
                  class="mx-0 my-0"
                  v-model="booking.open_dated"
                  label="Open Dated"
                  @change="toggleOpenDated"
                  :disabled="
                    !booking.booking_type ||
                      !booking.product_type ||
                      !booking.facility_id
                  "
                ></v-switch>
              </v-col> -->

              <v-col cols="12" sm="3" md="3" v-if="booking.booking_type === 6">
                <v-menu
                  solo
                  filled
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <label for="">Date</label>
                    <v-text-field
                      outlined
                      background-color="#fff"
                      v-model="date1Formatted"
                      readonly
                      v-on="on"
                      @change="getCapacity"
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="booking.booking_date"
                    @input="menu1 = false"
                    @change="getCapacity(), getProducts()"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="3" md="3" v-if="booking.booking_type === 6">
                <label for="">From*</label>
                <v-select
                  :items="timings.slice(0, timings.length - 1)"
                  label=""
                  item-text="text"
                  item-value="value"
                  :rules="[(v) => !!v || 'From time is required']"
                  v-model="booking.from_time"
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  @change="updateEndTime"
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3" v-if="booking.booking_type === 6">
                <label for="">To*</label>
                <v-select
                  :items="timings.slice(1)"
                  label=""
                  item-text="text"
                  item-value="value"
                  v-model="booking.to_time"
                  :rules="[(v) => !!v || 'To time is required']"
                  required
                  outlined
                  readonly
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3"  v-if="booking.booking_type === 6">
                <label for="">Product*</label>
                <v-select
                  v-model="booking.product"
                  :items="product_type"
                  item-value="product_id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  label=""
                  required
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                  :rules="[(v) => !!v || 'Product is required']"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3" v-else>
                <label for="">Product*</label>
                <v-select
                  v-model="booking.ticket"
                  :items="eventTickets"
                  item-value="product_id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="product_name"
                  label=""
                  required
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                  :rules="[(v) => !!v || 'Product is required']"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <label for="">Quantity</label>
                <v-text-field
                  label=""
                  outlined
                  background-color="#fff"
                  type="number"
                  min="1"
                  v-model="booking.quantity"
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
                ></v-text-field>
              </v-col>
              <v-col
                class="titles mt-2"
                style="font-size:16px"
                md="12"
                lg="12"
                cols="12"
              >
                Add Participants:
              </v-col>
              <v-col cols="12" md="12" lg="12">
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="8" lg="8"
                        ><v-radio-group
                          v-model="booking.upload"
                          row
                          class="pl-4"
                          label="Participants"
                        >
                          <v-radio label="Upload" value="true"></v-radio>
                          <v-radio
                            label="Manual"
                            value="false"
                          ></v-radio> </v-radio-group
                      ></v-col>
                      <v-col
                        v-if="
                          enablePerDayCapacity == 1 &&
                          available_capacity != null &&
                          max_capacity != null
                        "
                        cols="12"
                        md="4"
                        lg="4"
                        ><span class="titles">Capacity:</span
                        >{{ available_capacity }}/{{ max_capacity }}</v-col
                      >
                    </v-row>

                    <v-container v-if="booking.upload == 'true'">
                      <v-row align="center">
                        <v-col cols="12" sm="6" md="6">
                          <label for="">Customers list</label>
                          <v-file-input
                            label=""
                            v-model="booking.participant_document"
                            prepend-inner-icon="mdi-paperclip"
                            prepend-icon
                            outlined
                            required
                            background-color="#fff"
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                          >
                          </v-file-input>
                        </v-col>
                        <v-col cols="12" md="2" lg="2">
                          <v-btn
                            small
                            block
                            color="white"
                            style="color:black; height:30px; margin-top:20px"
                            dark
                            @click="downloadSampleFile()"
                            v-if="booking.product_type || booking.booking_type === 3"
                          >
                            Sample Xlsx
                            <v-icon right dark>
                              mdi-download
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else :key="Object.keys(fields).length">
                      <v-row
                        v-for="(customer, k) in booking.customers"
                        :key="k"
                        class="border-bottom pb-2"
                      >
                        <div class="mb-0 w-full d-flex align-center ml-3 mt-2">
                          <p class="customer-number">Customer {{ k+1 }}</p>
                        </div>
                        <v-col cols="12" md="4" lg="4" v-if="!$store.getters.venueInfo.enable_icp">
                          <label for="">Name*</label>
                          <v-text-field
                            v-model="customer.name"
                            outlined
                            background-color="#fff"
                            label=""
                            required
                            :rules="nameRule"
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="4" v-if="!$store.getters.venueInfo.enable_icp">
                          <label for="">Email*</label>
                          <v-text-field
                            v-model="customer.email"
                            outlined
                            background-color="#fff"
                            label=""
                            required
                            :rules="emailRule"
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="4" v-if="!$store.getters.venueInfo.enable_icp">
                          <label for="">Mobile</label>
                          <mobile-number-field
                              v-model="customer.phone"
                              :rules="phoneRule"
                              :dense="true"
                              :outlined="true"
                              :variant="1"
                          />
                        </v-col>
                        <v-col sm="6" md="4" v-if="fields.id && fields.id.is_visible">
                          <label for="">ID Type{{ fields.id.is_required ? '*' : '' }}</label>
                          <v-select
                              :hint="`ID Type${fields.id.is_required ? '*' : ''}`"
                              label=""
                              :rules="idTypeRule"
                              outlined
                              :menu-props="{ bottom: true, offsetY: true }"
                              item-value="id"
                              item-text="name"
                              :items="idProofTypes"
                              v-model="customer.id_proof_type_id"
                              background-color="#fff"
                              class="q-autocomplete shadow-0"
                              dense
                              validate-on-blur
                          ></v-select>
                        </v-col>
                        <v-col sm="6" md="4" v-if="fields.id && fields.id.is_visible">
                          <label for="">ID Number{{ fields.id.is_required ? '*' : '' }}</label>
                          <v-text-field
                              :hint="`ID Number${fields.id.is_required ? '*' : ''}`"
                              label=""
                              :rules="idTypeRule"
                              outlined
                              v-model="customer.id_proof_number"
                              background-color="#fff"
                              class="q-text-field shadow-0 text_field1"
                              dense
                              validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6" md="4"
                          v-if="fields.gender && fields.gender.is_visible"
                        >
                          <label for="">Gender {{ fields.gender.is_required === 1 ? '*' : ''}}</label>
                          <v-select
                            v-model="customer.gender"
                            :items="['Male', 'Female']"
                            label=""
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#fff"
                            :required="fields.gender.is_required === 1"
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6" md="4"
                          v-if="fields.dob && fields.dob.is_visible"
                        >
                          <label for="">Date of Birth {{
                              fields.dob.is_required === 1 ? '*' : ''
                            }}</label>
                          <date-of-birth
                            label=""
                            :rules="dobRule"
                            v-model="customer.dob"
                            :dense="true"
                            class-name="q-text-field shadow-0  add-on"
                            hide-details="auto"
                          >
                          </date-of-birth>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6" md="4"
                          v-if="fields.nationality && fields.nationality.is_visible"
                        >
                          <label for="">Nationality {{ fields.nationality.is_required? '*' : ''}}</label>
                          <v-autocomplete
                            outlined
                            label=""
                            v-model="customer.nationality"
                            item-value="name"
                            item-text="name"
                            :items="countries"
                            :rules="nationalityRule"
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                          ></v-autocomplete>
                        </v-col>
                        <v-col sm="4" md="4" v-if="fields.tag.is_visible">
                          <label for="">Tags{{ fields.tag.is_required ? '*' : '' }}</label>
                          <v-select
                              :items="tags"
                              :placeholder="`Tags${fields.tag.is_required ? '*' : ''}`"
                              label=""
                              outlined
                              item-value="id"
                              item-text="name"
                              :menu-props="{ bottom: true, offsetY: true }"
                              v-model="customer.customer_tag"
                              :rules="tagRule"
                              background-color="#fff"
                              class="q-autocomplete shadow-0"
                              dense
                              validate-on-blur
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            text
            @click="closeDialogueConfirm"
            >Close</v-btn
          >
          <v-btn class="ma-2 white--text blue-color" text @click="validateBooking">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>
<script>
import moment from "moment";
import b2b from "@/mixins/b2b";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
export default {
  components: {MobileNumberField, ConfirmModel },
  mounted() {
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getFieldConfigurations.status == false) {
      this.$store.dispatch("loadEventFieldConfigurations");
    }
    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.checkProductEnable();
        // this.checkOpenDatedEnable();
      });
    } else {
      this.checkProductEnable();
      // this.checkOpenDatedEnable();
    }
  },
  mixins: [b2b],
  computed: {
    tags() {
      return this.$store.getters.getTags.data;
    },
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
    nameRule() {
      const rules = [];
      const rule = (v) => !!v || "Name is required";
      rules.push(rule);
      return rules;
    },
    phoneRule() {
      const rules = [];
      const rule = (v) => !!v || "Phone is required";
      rules.push(rule);

      return rules;
    },
    emailRule() {
      const rules = [];
      const rule1 = (v) => !!v || "Email is required";
      rules.push(rule1);

      const rule2 = (v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid";
      rules.push(rule2);
      return rules;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter((element) => {
        return element.per_capacity === 1 && element.is_golf_enabled === 0;
      });
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data.filter((element) => {
        return [3, 6].includes(element.id);
      });
    },
    date1Formatted() {
      return moment(this.booking.booking_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    // fields() {
    //   let configs = [];
    //   if(this.booking.booking_type == 3){
    //     configs = this.$store.getters.getFieldConfigurations.data;
    //   }else{
    //     configs = this.$store.getters.getBookingFieldsByVenueServiceId(
    //       this.booking.product_type
    //     );
    //   }
    //   let fieldsObj = {};
    //   configs.forEach((config) => {
    //     fieldsObj = { ...fieldsObj, [config.slug]: config };
    //   });
    //   console.log(fieldsObj);
    //   return fieldsObj;
    // },
    // fields() {
    //   return this.getFields();
    // },
    bookingType() {
      return this.booking.booking_type;
    },
    productType() {
      return this.booking.product_type;
    },
    fieldConfigurations() {
      return this.$store.getters.getFieldConfigurations.data;
    },
    bookingFieldsByVenueServiceId() {
      return this.$store.getters.getBookingFieldsByVenueServiceId(this.productType);
    },

    countries() {
      return this.$store.getters.getCountries.data;
    },

    dobRule() {
      const rules = [];
      if (this.fields.dob && this.fields.dob.is_required) {
        const rule = (v) => !!v || "Date of Birth is required";
        rules.push(rule);
      }
      return rules;
    },
    idTypeRule() {
      const rules = [];
      if (this.fields.id.is_required) {
        const rule = (v) => !!v || "Please select ID Proof Type";
        rules.push(rule);
      }
      return rules;
    },
    nationalityRule() {
      const rules = [];
      if (this.fields.nationality.is_required) {
        const rule = (v) => !!v || "Please select nationality";
        rules.push(rule);
      }
      return rules;
    },
    tagRule() {
      const rules = [];
      if (this.fields.tag.is_required) {
        const rule = (v) => !!v || "Tag is required";
        rules.push(rule);
      }
      return rules;
    }
  },
  props: {
    addBookingDialogue: { type: Boolean, default: false },
    b2bId: {
      type: Number,
      default: null,
    },
    entityType: {
      type: String,
      default: "b2b",
    },
  },
  data() {
    return {
      idProofTypes: [{name: "Emirates Id",id: 32}, {name: "Passport", id: 33 }],
      // openDated: 0,
      fields:[],
      allowProducts: 0,
      sampleXlsFile: "import/customers.xlsx",
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      max_capacity: null,
      available_capacity: null,
      menu1: false,
      valid: true,
      booking: {
        booking_type: null,
        product_type: null,
        open_dated: false,
        product: null,
        booking_date: moment().format("YYYY-MM-DD"),
        time_slot: null,
        from_time: null,
        to_time: null,
        upload: "true",
        upload_file: null,
        participant_document: null,
        customers: [
          {
            name: null,
            email: null,
            phone: null,
          },
        ],
        ticket_type: null,
        guests: [],
        quantity: 1,
        customer_count: 1,
      },
      // booking_types: [
      //   { id: 6, name: "Facility" },
      //   { id: 3, name: "Event" },
      // ],
      product_type: [
        { id: 1, name: "Adult pass (19+)" },
        { id: 2, name: "Teen pass (13-19)" },
        { id: 3, name: "Kids pass (Under 13)" },
      ],
      // timeslots: [
      //   { id: 1, name: " 08 AM to 12 PM" },
      //   { id: 2, name: " 12 PM to 04 PM" },
      //   { id: 3, name: " 04 PM to 08 PM" },
      // ],
      facility: {
        enablePerDayCapacity: 0,
        capacityType: "slot",
        childFacility: "0",
        is_public: 0,
        facility_rentals: [
          {
            timing_template: null,
            price: null,
            weekdays: [],
            start_time: null,
            end_time: null,
          },
        ],
        open_days: [],
        facility_game_formations: [],
        product_categories: [],
        child_facilities: [],
        deleted_rentals: [],
      },
      facilities: [],
      events: [],
      eventTickets: [],
      timings: [],
      enablePerDayCapacity: 0,
      timeIncrement: 60,
      is_online_btn_show: 0,
      mixedConfig: false,
      isOnlineBtnDisabled: false,
      isGlobalPerCapacity: 0,
      isGolfEnabled: false,
      perCapacity: null,
      min_booking_time: null,
    };
  },
  methods: {
    getFields() {
      let configs = [];
      if (this.bookingType === 3) {
        configs = this.fieldConfigurations;
      } else {
        configs = this.bookingFieldsByVenueServiceId;
      }
      let fieldsObj = {};
      configs.forEach(config => {
        fieldsObj = { ...fieldsObj, [config.slug]: config };
      });
      this.fields = fieldsObj;
      console.log("this.fields");
      console.log(this.fields);
    },
    // toggleOpenDated() {
    //   if (this.booking.open_dated == 1) {
    //     this.booking.booking_date = moment()
    //       .add(1, "Days")
    //       .format("YYYY-MM-DD");
    //   } else {
    //     this.booking.booking_date = moment().format("YYYY-MM-DD");
    //   }
    //   this.getCapacity();
    //   this.getProducts();
    // },
    checkProductEnable() {
      if (this.b2bConfig && this.b2bConfig.enable_specific_products) {
        this.allowProducts = 1;
      } else {
        this.allowProducts = 0;
      }
    },
    // checkOpenDatedEnable() {
    //   this.openDated = 0;
    //   if (this.b2bConfig) {
    //     if (
    //       (this.entityType == "B2B" && this.b2bConfig.b2b_open_dated == 1) ||
    //       (this.entityType == "B2G" && this.b2bConfig.b2g_open_dated == 1) ||
    //       (this.entityType == "B2E" && this.b2bConfig.b2e_open_dated == 1)
    //     ) {
    //       console.log("yes");
    //       this.openDated = 1;
    //     } else {
    //       this.openDated = 0;
    //     }
    //   } else {
    //     this.openDated = 0;
    //   }
    // },
    updateEndTime() {
      if (this.booking.from_time && this.booking.from_time != null) {
        let endTime = moment(this.booking.from_time, "HH:mm:ss");
        if (endTime) {
          endTime = endTime
            .add(this.min_booking_time, "minutes")
            .format("HH:mm:ss");
        }
        this.booking.to_time = endTime;
        this.getProducts();
        this.$forceUpdate();
      }
    },
    rentalChange() {
      this.facility.facility_rentals[0].timing_template = null;
      this.facility.facility_rentals[0].timing_template_id = null;
    },
    deleteCustomer(index) {
      this.booking.customers.splice(index, 1);
    },
    addCustomer() {
      this.booking.customers.push({
        name: null,
        email: null,
        phone: null,
      });
    },
    closeDialogueConfirm() {
      this.confirmModel = {
        id: 1,
        title: "Do you want to close?",
        description:
          "All entered data will be lost, By clicking <b>Yes</b> you can confirm the operation",
        type: "close_confirm",
      };
    },
    confirmActions(data) {
      if (data.type == "close_confirm") {
        this.closeDialogue();
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    closeDialogue() {
      this.booking = {
        booking_type: null,
        product_type: null,
        open_dated: false,
        product: null,
        booking_date: moment().format("YYYY-MM-DD"),
        time_slot: null,
        upload: "true",
        upload_file: null,
        customers: [
          {
            name: null,
            email: null,
            phone: null,
          },
        ],
        ticket_type: null,
        guests: [],
      };
      this.$refs.form.resetValidation();
      this.$emit("close");
    },
    getConfigurations() {
      if (
        this.booking.booking_type == null ||
        this.booking.product_type == null
      ) {
        if(this.booking.booking_date == 6){
          return;
        }else{
          this.getEvents();
          return;
        }
      }
      if (
        !this.$store.getters.getConfigurationStatus(this.booking.product_type)
      ) {
        this.$store
          .dispatch(
            "loadConfigurationsByVenueServiceId",
            this.booking.product_type
          )
          .then((response) => {
            if (response.status == 200) {
              this.setConfiguration();
              this.$forceUpdate();
            }
          });
      } else {
        this.setConfiguration();
      }
      this.getCapacity();
      this.getFacilities();
      this.getEvents();
      this.getProducts();
    },
    setConfiguration() {
      let data = this.$store.getters.getConfigurationByVenueServiceId(
        this.booking.product_type
      );
      this.mixedConfig = data.mixed_config;
      this.perCapacity = data.per_capacity.toString();
      this.isGlobalPerCapacity = data.per_capacity.toString();
      if (this.isGolfEnabled) {
        this.perCapacity = "1";
        this.facility.is_public = 0;
      }
      this.is_online_btn_show = data.is_public;
      if (this.isGlobalPerCapacity == "1") {
        this.facility.is_public = 0;
        this.perCapacity = "1";
      }
      this.facility.min_booking_time =
        data.min_booking_time != null ? data.min_booking_time : 60;
      this.min_booking_time =
        data.min_booking_time != null ? data.min_booking_time : 60;

      this.timeIncrement =
        data.time_increment != null ? data.time_increment : 60;
      this.generateTiming();
    },
    generateTiming() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.timings = [];
      this.timings.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.timings.push(data);
          hour = 24;
        } else {
          this.timings.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
    getProducts() {
      this.product_type = [];
      if (this.booking.from_time == null || this.booking.booking_date == null) {
        return;
      }
      this.$http
        .get(
          `venues/facilities/bookings/utils?facility_id=${this.booking.facility_id}&date=${this.booking.booking_date}&is_b2b=1&b2b_id=${this.b2bId}&start_time=${this.booking.from_time}&increment=${this.timeIncrement}&min_booking_time=${this.facility.min_booking_time}&booking_id=0&per_capacity=1&venue_service_id=${this.booking.product_type}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.product_type = data.facility_rentals;
            let B2bProducts = response.data.b2b;
            if (this.allowProducts && B2bProducts.length <= 0) {
              this.product_type = [];
            } else if (this.allowProducts) {
              this.product_type = this.product_type.filter((productType) =>
                B2bProducts.some(
                  (b2bProduct) =>
                    b2bProduct.product_id === productType.product_id
                )
              );

              const updatedProductTypes = this.product_type.map(
                (productType) => {
                  const matchingB2bProduct = B2bProducts.find(
                    (b2bProduct) =>
                      b2bProduct.product_id === productType.product_id
                  );
                  return {
                    ...productType,
                    tax_amount: matchingB2bProduct.tax_amount,
                    price: matchingB2bProduct.price,
                    total_amount: matchingB2bProduct.total_price,
                  };
                }
              );
              this.product_type = updatedProductTypes;
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
      // let url = `?product_type=${this.booking.booking_type}&venue_service_id=${this.booking.product_type}&date=${this.booking.booking_date}`;
      // if (this.booking.from_time != null && this.booking.to_time != null) {
      //   url += `&start_time=${this.booking.from_time}&end_time=${this.booking.to_time}`;
      // }
      // this.$http
      //   .get(`venues/b2b/booking/get-products${url}`)
      //   .then((response) => {
      //     if (response.status == 200) {
      //       const data = response.data.data;
      //       this.product_type = data;
      //     }
      //   })
      //   .catch((error) => {
      //     this.errorChecker(error);
      //   });
    },
    getFacilities() {
      if (this.booking.booking_type === 3) {
        return;
      }
      if (this.booking.product_type == null) {
        this.showInfo("Please add service");
        return;
      }
      this.showLoader("Loading Facilities");
      this.facilities = [];
      this.$http
        .get(
          `venues/facilities?type=ground&venue_service_id=${this.booking.product_type}&capacity_based=1`
        )
        .then((response) => {
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data.length
          ) {
            this.facilities = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        }).finally(() => {
        this.hideLoader();
      });
    },
    getEvents() {
      if (this.booking.booking_type === 6) {
        return;
      }
      this.showLoader("Loading Events");
      this.events = [];

      this.$http
        .get(
          `venues/b2b/booking/events/${this.b2bId}`
        )
        .then((response) => {
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data.length
          ) {
            this.events = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        }).finally(() => {
        this.hideLoader();
      });
    },
    getEventTickets() {
      if (this.booking.booking_type === 6) {
        return;
      }
      this.product_type = [];
      let id = this.booking.event_id;
      this.showLoader("Loading Event Tickets");
      this.$http
        .get(
          `venues/b2b/booking/events/products/${id}`
        )
        .then((response) => {
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data.length
          ) {
            this.eventTickets = response.data.data;
            this.product_type = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        }).finally(() => {
        this.hideLoader();
      });
    },
    getCapacity() {
      let url = `?venue_service_id=${this.booking.product_type}&date=${this.booking.booking_date}`;
      this.$http
        .get(`venues/b2b/facility/capacity${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.max_capacity = data.capacity;
            let avail = data.capacity - data.available;
            this.available_capacity = avail < 0 ? 0 : avail;
            this.enablePerDayCapacity = data.is_enable_per_day_capacity;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    downloadSampleFile() {
      this.$http
        .get("/venues/b2b/download-sample", {
          responseType: "blob",
          params: {
            venue_service_id: this.booking.product_type,
            booking_type: this.booking.booking_type,
            tickets: this.booking.customer_count,
            b2bId: this.b2bId,
          },
        })
        .then((res) => {
          this.downloadFile(res, "Customer_sample_");
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    refillCustomers(value) {
      const product = this.product_type.find(
        (prod) => prod.product_id == this.booking.product
      );
      if (product) {
        value = value * product.participant_count;
      }
      this.booking.customer_count = value;
      if (this.booking.customers.length <= value) {
        const diff = value - this.booking.customers.length;
        for (let i = 0; i < diff; i++) {
          this.addCustomer();
        }
      } else if (this.booking.customers.length > value) {
        const diff = this.booking.customers.length - value;
        for (let i = 0; i < diff; i++) {
          this.deleteCustomer(this.booking.customers.length - 1);
        }
      }
    },

    validateBooking(){
      if(this.booking.upload == "true" && !this.booking.participant_document){
        this.showError("File is required for booking");
        return; // Stop form submission
      }else{
        this.makeBooking();
      }
    }
  },
  watch: {
    bookingType: 'getFields',
    productType: 'getFields',
    fieldConfigurations: 'getFields',
    bookingFieldsByVenueServiceId: 'getFields',
    "booking.quantity"(value) {
      this.refillCustomers(value);
    },
    "booking.product"() {
      this.refillCustomers(this.booking.quantity);
    },
  },
};
</script>
<style>
.customer-number{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}
</style>
