<template>
  <div class="md-card md-theme-default mt-8 shadow rounded-5">
    <div class="md-card-content md-card-table">
      <div>
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="header_title mb-0">Financials</div>
          <div class="d-flex justify-end align-center gap-x-4 gap-y-4">
            <v-autocomplete
                v-model="searchParams.time_intervel"
                :items="timeDuration"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                item-text="title"
                item-value="name"
                outlined
                placeholder="Time Period"
                @change="changeFxn"
            ></v-autocomplete>
            <template v-if="flag">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  dense
                  max-width="200px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="date1Formatted"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.start_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  dense
                  max-width="200px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="date2Formatted"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      readonly
                      v-on="on"

                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.end_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn class="mr-2" color="#00b0af" dark @click="search">
                Go
              </v-btn>
            </template>
          </div>
        </div>


        <div
            class="md-content md-table md-theme-default table_borders shadow-0"
        >
          <div
              class="md-content md-table-content md-scrollbar md-theme-default"
          >
            <div class="table-responsive">
              <table class="table border-collapse ">
                <thead>
                <tr class="opacity-70 tr-neon tr-rounded ">
                  <th>
                    <div>
                      Sr no.
                    </div>
                  </th>
                  <th>
                    <div>Invoice Month</div>
                  </th>
                  <th>
                    <div>Total Amount</div>
                  </th>
                  <th>
                    <div>Paid Amount</div>
                  </th>
                  <th style="position: relative">
                    <div>Pending Amount</div>
                  </th>
                  <th>
                    <div>Status</div>
                  </th>
                  <!-- <th >
                      <div >Transaction Status</div>
                  </th> -->
                  <th>
                    <div>Action</div>
                  </th>
                </tr>
                </thead>

                <tbody v-if="searchDatas && searchDatas.length > 0">
                <tr
                    v-for="(data, index) in searchDatas"
                    :key="index"
                    class="md-table-row"
                >
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <span class="text-truncate">{{ index + 1 }}</span>
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.invoice_month }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.total_amount | toCurrency }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.total_paid | toCurrency }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.total_outstanding | toCurrency }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.min_settled_invoice_id ? "Paid" : "Pending" }}
                    </div>
                  </td>
                  <!-- <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.status }}
                    </div>
                  </td> -->
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center pa-3" colspan="7">
                    <span> No records</span>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="4">
            <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
              <div style="width: 80px">
                <v-select
                    v-model="perPage"
                    :items="[10, 15, 25, 50]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="q-autocomplete text-sm"
                    flat
                    hide-details
                    solo
                    @change="search()"
                ></v-select>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <b2b-add-bookings
        :addBookingDialogue="addDialogue"
        :b2bId="b2bId"
        :entityType="entity_type"
        @close="(addDialogue = false), search()"
    />
    <order-details
        :id="orderId"
        :ids="orderIds"
        :log="true"
        @close="(orderId = null), (orderIds = null), search()"
    />
  </div>
</template>
<script>
import moment from "moment";
import B2bAddBookings from "@/components/B2b/B2bAddBooking.vue";

import OrderDetails from "../../components/Order/OrderDetails.vue";

export default {
  components: {
    "b2b-add-bookings": B2bAddBookings,
    OrderDetails,
  },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
    b2bId: {
      type: Number,
      default: null,
    },
    entity_type: {
      type: String,
      default: "b2b",
    },
  },
  watch: {
    page() {
      this.search();
    },
  },
  data() {
    return {
      orderId: null,
      orderIds: null,
      addDialogue: false,
      searchDatas: [],
      menu1: false,
      menu2: false,
      flag: false,
      currentPage: 0,
      pageSize: 10,
      page: 1,
      perPage: 10,
      totalPages: 1,
      timeDuration: [
        {name: "All", title: "All"},
        {name: "day", title: "This Day"},
        {name: "week", title: "This Week"},
        {name: "month", title: "This Month"},
        {name: "year", title: "This Year"},
        {name: "custom", title: "Custom Duration"},
      ],
      columnData: [
        {name: "Due date", value: "due_date"},
        {name: "Timestamp", value: "timestamp"},
        {name: "Guests", value: "guests"},
        {name: "Guest details", value: "guest_details"},
        {name: "Tickets", value: "tickets"},
        {name: "Transaction Status", value: "invoice_status"},
        {name: "Order Status", value: "order_payment_status"},
        {name: "Bill", value: "bill"},
      ],
      columns: [
        "invoice_status",
        "order_payment_status",
        "guest_details",
        "tickets",
        "guests",
        "timestamp",
        "due_date",
        "bill",
      ],
      orderBy: "id",
      orderDir: "DESC",
      searchParams: {
        start_date: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    getOrderDetails(data) {
      this.orderId = data.id;
    },

    exportData() {
      let url = "";
      url = "&status=all";
      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }

      this.$http
          .get(`venues/b2b/booking/export-all?b2b_id=${this.b2bId}${url}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.downloadFile(response, "Bookings_Export_");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    exportIndividual(id) {
      this.$http
          .get(`venues/b2b/booking/export?order_id=${id}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.downloadFile(response, "Customer_Export_");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.search();
      }
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    search() {
      console.log("done");
      let url = "";
      url = "&status=all";

      //   if (
      //     this.searchParams.time_intervel != "All" &&
      //     this.searchParams.time_intervel != null
      //   ) {
      //     // this.searchParams.end_date = moment().format("YYYY-MM-DD");
      //     if (this.searchParams.time_intervel == "custom") {
      //       this.flag = true;
      //     } else if (this.searchParams.time_intervel == "day") {
      //       this.searchParams.start_date = moment().format("YYYY-MM-DD");
      //     } else if (this.searchParams.time_intervel == "week") {
      //       this.searchParams.start_date = moment()
      //         .startOf("week")
      //         .format("YYYY-MM-DD");
      //     } else if (this.searchParams.time_intervel == "month") {
      //       this.searchParams.start_date = moment()
      //         .startOf("month")
      //         .format("YYYY-MM-DD");
      //     } else if (this.searchParams.time_intervel == "year") {
      //       this.searchParams.start_date = moment()
      //         .startOf("year")
      //         .format("YYYY-MM-DD");
      //     }
      //     url += "&end_date=" + this.searchParams.end_date;
      //     url += "&start_date=" + this.searchParams.start_date;
      //   }

      this.$http
          .get(
              `venues/b2b/sales/${this.b2bId}?page=` +
              this.page +
              "&per_page=" +
              (this.perPage != null ? this.perPage : 10) +
              url
          )
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status) {
              this.searchDatas = response.data.data;
              this.totalPages = response.data.total_pages;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
  },
};
</script>
